@import './palettes';

$pallets: primary, accent, ruby, grey;
$styles: light, dark;
$types: normal, contrast;
$palettesMap: (
	primary-light: $primary-palette-light,
	primary-dark: $primary-palette-dark,
	accent-light: $accent-palette-light,
	accent-dark: $accent-palette-dark,
	ruby-light: $mat-ruby-red,
	ruby-dark: $mat-ruby-red,
	grey-light: $mat-grey-light,
	grey-dark: $mat-grey-dark,
);

@each $palette in $pallets {
	@each $style in $styles {
		@each $type in $types {
			@each $key, $colorHex in map-get($palettesMap, #{$palette}-#{$style}) {
				@if ($key != 'contrast') {
					body {
						.mat-background-#{'' + $palette}-#{$style}-#{$key} {
							background: $colorHex;
						}
						@if ($style != 'dark') {
							.mat-background-#{'' + $palette}-#{$key} {
								background: $colorHex;
							}
						}
						@if ($style == 'dark') {
							&.dark {
								.mat-background-#{'' + $palette}-#{$key} {
									background: $colorHex;
								}
							}
						}
					}
				}
				@if ($key == 'contrast') {
					@each $contrastKey, $contrastColorHex in $colorHex {
						body {
							.mat-background-#{'' + $palette}-#{$style}-#{$contrastKey}-#{$key} {
								background: $contrastColorHex;
							}
							@if ($style != 'dark') {
								.mat-background-#{'' + $palette}-#{$contrastKey}-#{$key} {
									background: $contrastColorHex;
								}
							}
							@if ($style == 'dark') {
								&.dark {
									.mat-background-#{'' + $palette}-#{$contrastKey}-#{$key} {
										background: $contrastColorHex;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
