@use '@angular/material' as mat;
@import '../variables';

@mixin paginator-theme($theme) {
	@include mat.legacy-paginator-theme($theme);
	$foreground: map-get($theme, foreground);
	$foreground-text: mat.get-color-from-palette($foreground, 'text');
	$background: map-get($theme, background);
	$background-color: mat.get-color-from-palette($background, 'background');
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$grey: map-get($config, grey);

	.mat-paginator {
		background-color: $background-color !important;
		color: $foreground-text;

		.mat-paginator-page-size {
			align-items: center;
		}

		.mat-paginator-page-size-select {
			border: 1px solid mat.get-color-from-palette($primary, '400-contrast');
			padding: 0 5px 0 19px;
			width: 75px;
			border-radius: 4px;
			margin: 0 4px 0 4px;

			.mat-select-trigger {
				font-size: 14px;

				.mat-select-arrow {
					border: 0;
					height: 15px;
					width: 15px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='30px' viewBox='0 0 24 24' width='30px' fill='%23FF5C20'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E%0A");
					background-position: center;
					background-repeat: no-repeat;
				}
			}

			.mat-form-field-wrapper {
				padding: 0;
			}

			.mat-form-field-underline {
				display: none;
			}

			.mat-form-field-infix {
				padding: 8px 0;
				border: 0;
			}
		}

		.mat-paginator-range-actions {
			.mat-button-base {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid;
				border-radius: 4px;
				width: 34px;
				height: 34px;

				&:not(.mat-button-disabled) {
					color: mat.get-color-from-palette($primary, '400-contrast');
				}

				&.mat-button-disabled {
					color: mat.get-color-from-palette($grey);
				}
			}

			.mat-paginator-navigation-previous {
				margin: 0 4px;
			}

			.mat-paginator-navigation-next {
				margin: 0 4px;
			}
		}
	}
}
