@import 'styles/bootstrap';
@import '@ctrl/ngx-emoji-mart/picker';
@import 'material-icons/iconfont/material-icons';
@import 'styles/mode-colors';
@import 'styles/variables';
@import 'styles/global';
@import 'styles/utils';
@import 'app/modules/shared/components/dropdowns/providers/custom-overlay/custom-overlay';
@import 'theme/index';
@import 'styles/overrides/index';

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dark .mat-focused [type='text'] {
	color: white !important;
}

.dark .move-footer a {
	color: #fff;
}

.dark .move-footer {
	background: #2d2c2c8c;
	border-top: 1px solid #979797;
}

.light .move-footer {
	background: #9e9e9e85;
	border-top: 1px solid #828282;
	color: #ffffff;
}

.light .mat-focused [type='text'] {
	color: black !important;
}

.dark .chat-search-container {
	background: #282828 !important;
}

.color-tag {
	box-shadow: none !important;
	width: 32px !important;
	height: 32px !important;
}

.tag-active-light {
	-webkit-box-shadow: inset 0px 0px 0px 3px #000000 !important;
	-moz-box-shadow: inset 0px 0px 0px 3px #000000 !important;
	box-shadow: inset 0px 0px 0px 3px #000000 !important;
}

.tag-active-dark {
	-webkit-box-shadow: inset 0px 0px 0px 3px #ffffff !important;
	-moz-box-shadow: inset 0px 0px 0px 3px #ffffff !important;
	box-shadow: inset 0px 0px 0px 3px #ffffff !important;
}

.text-message {
	max-width: 420px;
}

.chat-navbar .icon {
	opacity: 0.8;
	transition: all 0.195s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.chat-navbar .icon:hover {
	opacity: 1;
}

.chat-navbar .icon:active {
	color: #ff5c20;
}

.project-content {
	transition: background 0.195s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.dark .tag-red {
	background-color: rgba(209, 44, 63, 0.2) !important;
}

.dark .tag-orange {
	background-color: rgba(245, 173, 58, 0.2) !important;
}

.dragover {
	background-color: #000;
	opacity: 0.5;
	position: relative;
	z-index: 100;
	min-height: 100%;
	display: block;
	width: 100%;
}

.drag-content {
	display: none;
}

.dark .tag-purple {
	background-color: rgba(197, 61, 224, 0.2) !important;
}

.dark .tag-green {
	background-color: rgba(125, 211, 34, 0.2) !important;
}

.dark .tag-blue {
	background-color: rgba(74, 144, 226, 0.2) !important;
}

.dark .menu_button {
	color: #ffffff;
}

.dark .tag-yellow {
	background-color: rgba(247, 234, 52, 0.2) !important;
}

.dark .tag-teal {
	background-color: rgba(68, 215, 182, 0.2) !important;
}

.dark .tag-pink {
	background-color: rgba(223, 57, 142, 0.2) !important;
}

.dark .tag-dirty-green {
	background-color: rgba(177, 199, 144, 0.2) !important;
}

.dark .tag-royal-blue {
	background-color: rgba(34, 27, 222, 0.2) !important;
}

.dark .mat-menu-item {
	border-bottom: 1px solid #ccc3;
}

.dark .mat-menu-item:last-child {
	border: 0;
}

.light .mat-menu-item:last-child {
	border: 0;
}

.dark .sh-context-menu,
.dark .sh-context-menu--item:hover {
	background-color: #232323;
}

.light .sh-context-menu--item:hover {
	background-color: #ececec;
}

.light .sh-context-menu--item,
.dark .sh-context-menu--item {
	padding: 0;
}

.dark .form-control,
.dark .mat-form-field-infix,
.dark .date-row .mat-form-field-suffix {
	color: #fff;
}

.light .tags .tag__title {
	background-color: #9e9d9e;
}

.dark .tags .tag__title {
	background-color: #888888;
}

.dark .btn-link {
	color: #fff;
}

.dark ::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.light ::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #ff601c !important;
}

.dark ::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
.light ::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: #ff601c !important;
}

.facebook-login {
	background-color: #3d5798 !important;
}

.google-login {
	background-color: #de4b39 !important;
}

.apple-login {
	background-color: #000000 !important;
}

.dark ::ng-deep .export-panel .mat-radio-button.mat-accent .mat-radio-inner-circle,
.light ::ng-deep .export-panel .mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: #ff601c;
}

.light .btn-link {
	color: #000;
}

.ngb-tp .form-control {
	background-color: transparent;
}

.light .tag-red {
	background-color: rgba(209, 44, 63, 0.9) !important;
}

.light .tag-orange {
	background-color: rgba(245, 173, 58, 0.9) !important;
}

.light .tag-purple {
	background-color: rgba(197, 61, 224, 0.9) !important;
}

.light .tag-green {
	background-color: rgba(125, 211, 34, 0.9) !important;
}

.light .tag-blue {
	background-color: rgba(74, 144, 226, 0.9) !important;
}

.light .tag-yellow {
	background-color: rgba(247, 234, 52, 0.9) !important;
}

.light .tag-teal {
	background-color: rgba(68, 215, 182, 0.9) !important;
}

.light .tag-pink {
	background-color: rgba(223, 57, 142, 0.9) !important;
}

.light .tag-dirty-green {
	background-color: rgba(177, 199, 144, 0.9) !important;
}

.light .tag-royal-blue {
	background-color: rgba(34, 27, 222, 0.9) !important;
}

.light .info-message {
	background-color: #ededed;
}

.light .option-wrap {
	background-color: #f9f9f9 !important;
}

.light .delete-contact-icon,
.light .assignee-icon,
.light .pdf-icon,
.light .calendar-icon,
.light .description-icon {
	filter: invert(1);
}

.dark .invert-color {
	filter: brightness(0) invert(1);
}

.tag-red-border {
	background-color: rgb(209, 44, 63) !important;
}

.tag-orange-border {
	background-color: rgb(245, 173, 58) !important;
}

.tag-purple-border {
	background-color: rgb(197, 61, 224) !important;
}

.tag-green-border {
	background-color: rgb(125, 211, 34) !important;
}

.tag-blue-border {
	background-color: rgb(74, 144, 226) !important;
}

.tag-yellow-border {
	background-color: rgb(247, 234, 52) !important;
}

.tag-teal-border {
	background-color: rgb(68, 215, 182) !important;
}

.tag-pink-border {
	background-color: rgb(223, 57, 142) !important;
}

.tag-dirty-green-border {
	background-color: rgb(177, 199, 144) !important;
}

.tag-royal-blue-border {
	background-color: rgb(34, 27, 222) !important;
}

.tag-red-border-color {
	border-color: rgb(209, 44, 63) !important;
}

.tag-orange-border-color {
	border-color: rgb(245, 173, 58) !important;
}

.tag-purple-border-color {
	border-color: rgb(197, 61, 224) !important;
}

.tag-green-border-color {
	border-color: rgb(125, 211, 34) !important;
}

.tag-blue-border-color {
	border-color: rgb(74, 144, 226) !important;
}

.tag-yellow-border-color {
	border-color: rgb(247, 234, 52) !important;
}

.tag-teal-border-color {
	border-color: rgb(68, 215, 182) !important;
}

.tag-pink-border-color {
	border-color: rgb(223, 57, 142) !important;
}

.tag-dirty-green-border-color {
	border-color: rgb(177, 199, 144) !important;
}

.tag-royal-blue-border-color {
	border-color: rgb(34, 27, 222) !important;
}

.scroll-vertical {
	overflow-y: auto;
}

.divider {
	margin-bottom: 1px;
}

/* Light theme */

.light {
	background: #f9f8f7 !important;
}

.light svg.sorting-by__icon path {
	background: #fff !important;
	color: #111;
}

.light svg.sort-icon path {
	fill: #000;
}

.light .dark-grey {
	background-color: #cdcdcd;
}

.light .light-grey {
	background-color: #dedede;
}

.light footer a {
	color: #333;
	text-decoration: underline;
}

.light hr,
.light .divider {
	background-color: #bababa;
	height: 1px;
	border: 0;
}

.light .mat-ripple-element {
	background: rgba(239, 108, 0, 0.4) !important;
}

.light .primary-btn {
	background-color: #ff5c20 !important;
	font-weight: $font-weight-bold;
	font-size: 14px;
}

.light .btn-secondary {
	background-color: #333333 !important;
}

.light .warn-btn {
	height: 36px;
	font-size: 16px;
}

.light .textarea {
	color: #000;
}

.light .privacy-border {
	border: 1px solid #bababa !important;
}

.light .projects,
.light .app-task-members {
	background: #f9f9f9 !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.light .chat-navbar {
	background: #f9f9f9 !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	position: relative;
}

.light .chat-content {
	background: #fff !important;
}

.light .role {
	background: #ededed;
}

.light .members {
	background: #f9f9f9 !important;
}

.light .tagging-wrap {
	background: #f9f9f9 !important;
}

.light .project-content:hover {
	background: #282828;
	cursor: pointer;
}

.light .project-item hr {
	margin: 0;
}

.light .project-content:hover {
	background: #ededed;
	cursor: pointer;
}

.light .project-opened {
	background: #ededed;
	cursor: default;
}

.light .project-item hr {
	margin: 0;
	background: #afafaf;
}

.light .settings-other > div div:not(:nth-child(2n)) {
	background-color: rgba(200, 200, 200, 0.3);
	transition: background-color 0.195s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.light .settings-other > div div:not(:nth-child(2n)):hover {
	background-color: rgba(200, 200, 200, 0.5);
}

.light .project-actions > div:not(:nth-child(2n)) {
	background-color: rgba(200, 200, 200, 0.3);
}

.light .sort-control {
	background-color: #dfdfdf;
	border-radius: 6px 0 6px 6px;
	margin-bottom: 20px;
}

.light .active-tab-color {
	cursor: default;
	color: rgba(0, 0, 0, 1);
	line-height: 46px;
}

.light .inactive-tab-color {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.4);
	line-height: 46px;
}

.light .filter-icon-active {
	background-color: #dfdfdf;
	border-radius: 6px 6px 0 0;
}

.light .background-active {
	background-color: #cdcdcd !important;
	color: black !important;
	border-color: #cdcdcd !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.light .background-dialog-active {
	background-color: #cdcdcd !important;
	color: black !important;
	border-color: #cdcdcd !important;
	box-shadow: none !important;
}

.light .background-inactive {
	background-color: transparent !important;
	color: #888 !important;
	border-color: #cdcdcd !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.light .background-dialog-inactive {
	background-color: transparent !important;
	color: #888 !important;
	border-color: #cdcdcd !important;
	box-shadow: none !important;
}

.light .project-street-text {
	position: relative;
	font-size: 0.85em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	top: -8px;
}

.light .other-pdf {
	color: #cdcdcd;
}

.light .input-message {
	background-color: #fff;
	border: 1px #acacac solid;
	color: #545454;
}

.light .chat-button {
	background: #fff;
	border: 1px #acacac solid;
	color: #111;
}

.light .rule-error {
	background-color: #acacac;
}

.light .checkbox-workaround a {
	text-decoration: underline;
	color: #797979;
}

.light .auto-color {
	color: #111 !important;
}

.light .link-url {
	color: #fff !important;
}

.light .form-label {
	color: rgba(0, 0, 0, 0.54);
}

.light .mat-datetimepicker-calendar-header {
	color: black !important;
}

/* Dark theme */

.dark {
	background: #1a1a1a !important;
	color: #ffffff;
}

.dark svg.sorting-by__icon path {
	background: #1a1a1a !important;
	color: #ffffff;
	fill: #ffffff;
}

.dark .dark-grey {
	background-color: #575757;
}

.dark .light-grey {
	background-color: #858585;
}

.dark a {
	color: #777;
}

.dark footer a {
	color: #777;
	text-decoration: underline;
}

.dark hr,
.dark .divider {
	background-color: #545454;
	height: 1px;
	border: 0;
}

.dark .textarea {
	color: #fff;
}

.dark .privacy-border {
	border: 1px solid #575757 !important;
}

.dark .members {
	background: #1f1f1f !important;
}

.dark .tagging-wrap {
	background: #1f1f1f !important;
}

.dark .info-message {
	background-color: #262626;
}

.dark .role {
	background: #343434;
}

.dark .mat-ripple-element {
	background: rgba(239, 108, 0, 0.4) !important;
}

.dark .primary-btn {
	background-color: #ff5c20 !important;
	font-weight: $font-weight-bold;
	font-size: 14px;
}

.dark .warn-btn {
	height: 36px;
	font-size: 16px;
}

.light app-task-members {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.dark .chat-navbar {
	background: #282828 !important;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	position: relative;
}

.dark .chat-content {
	background: #1c1c1c;
}

.dark .project-content:hover {
	background: #303030;
	cursor: pointer;
}

.dark .project-opened {
	background: #343434;
	cursor: default;
}

.dark .project-item hr {
	margin: 0;
}

.dark .settings-other > div div:not(:nth-child(2n)) {
	background-color: rgba(66, 66, 66, 0.3);
	transition: background-color 0.195s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.dark .settings-other > div div:not(:nth-child(2n)):hover {
	background-color: rgba(66, 66, 66, 0.5);
}

.dark .project-actions > div:not(:nth-child(2n)) {
	background-color: rgba(60, 60, 60, 0.8);
}

.dark .sort-control {
	background-color: #323232;
	border-radius: 6px 0 6px 6px;
	margin-bottom: 20px;
}

.dark .active-tab-color {
	cursor: default;
	color: rgba(255, 255, 255, 1);
	line-height: 46px;
}

.dark .inactive-tab-color {
	cursor: pointer;
	color: rgba(255, 255, 255, 0.4);
	line-height: 46px;
}

.dark .filter-icon-active {
	background-color: #323232;
	border-radius: 6px 6px 0 0;
}

.dark .background-active {
	background-color: #4d4d4d !important;
	color: white !important;
	border-color: #4d4d4d !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.dark .background-dialog-active {
	background-color: #575757 !important;
	color: white !important;
	border-color: #575757 !important;
	box-shadow: none !important;
}

.dark .background-inactive {
	background-color: transparent !important;
	color: #4d4d4d !important;
	border-color: #4d4d4d !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.dark .background-dialog-inactive {
	background-color: transparent !important;
	color: white !important;
	border-color: #575757 !important;
	box-shadow: none !important;
}

.dark .project-street-text {
	position: relative;
	font-size: 0.85em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	top: -8px;
	color: #686868;
}

.dark .other-pdf {
	color: #212121;
}

.dark .input-message {
	background-color: #161616;
	border: 1px #5b5b5b solid;
	color: #fff;
}

.dark .chat-button {
	background: #161616;
	border: 1px #5b5b5b solid;
	color: #fff;
}

.dark .rule-error {
	background-color: #ffffff;
}

.dark .checkbox-workaround a {
	text-decoration: underline;
	color: #fff;
}

.dark .auto-color {
	color: white !important;
}

.dark .link-url {
	color: #fff !important;
}

.dark .table-mycrafty {
	color: white;
	background-color: #282828;
}

.dark .table-mycrafty thead th {
	border-bottom: 2px solid #1c1c1c !important;
}

.dark .table-mycrafty th,
.dark .table-mycrafty td {
	border-top: 1px solid #1c1c1c !important;
	background-color: #282828;
}

.dark .table-mycrafty th,
.dark .table-mycrafty thead {
	background-color: #282828;
}

.dark .table-mycrafty > tbody > tr:nth-child(odd) > td,
.dark .table-mycrafty > tbody > tr:nth-child(odd) > th {
	background-color: #303030;
}

.dark .table-mycrafty tbody tr:hover td,
.dark .table-mycrafty tbody tr:hover th {
	background-color: #453126;
}

.dark .form-label {
	color: rgba(255, 255, 255, 0.7);
}

.dark .mat-datetimepicker-content {
	background-color: #282828 !important;
}

.dark .mat-datetimepicker-calendar-table-header {
	color: white !important;
}

.dark .mat-datetimepicker-calendar-body-cell-content {
	color: white !important;
}

/* General */

.other-message {
	color: #141414;
}

.play-button {
	color: #ffffff !important;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.pointer {
	cursor: pointer;
}

.member-add-button .mat-fab .mat-button-wrapper {
	padding: 5px !important;
	position: relative;
	top: -1px;
}

.back-position {
	position: fixed;
	top: 50px;
	left: 52%;
	font-weight: $font-weight-normal;
}

a:hover {
	text-decoration: none !important;
}

.mat-calendar-body-disabled {
	opacity: 0.3;
}

.btn-white-text {
	color: rgb(255, 255, 255) !important;
}

.btn-white-text:disabled {
	color: rgba(255, 255, 255, 0.5) !important;
}

.mycrafty-navbar {
	background: var(--background-color);
	width: 100%;
	z-index: 99;
	height: 70px;
	border-bottom: 1px solid var(--sidebar-border-color);
}

.mat-snack-bar-container {
	background: #ff5c20 !important;
	font-weight: $font-weight-bold;
	color: #f2f2f2 !important;
}

.mat-snack-bar-container button {
	color: #f2f2f2 !important;
}

.badge-primary {
	background-color: #ff5c20;
}

.mat-form-field-label {
	font-weight: $font-weight-bold;
}

#remember-me .mat-checkbox-layout {
	margin: 0;
}

#remember-me .mat-checkbox-inner-container {
	width: 25px !important;
	border-radius: 4px;
	height: 25px !important;
}

.vertical-center-outer {
	display: table;
	position: absolute;
	height: 100%;
	width: 100%;
}

.vertical-center-middle {
	display: table-cell;
	vertical-align: middle;
}

.vertical-center-inner {
	margin-left: auto;
	margin-right: auto;
}

.authentication-viewer-outer {
	position: absolute;
	width: 100%;
}

.authentication-center-middle {
	vertical-align: middle;
	padding-right: 18%;
}

.icon {
	position: relative;
	vertical-align: middle;
	font-size: 30px;
	line-height: 32px;
}

.no-events {
	pointer-events: none !important;
}

.enable-events {
	pointer-events: all;
}

html {
	height: 100%;
}

body {
	height: 100%;
}

.body-mobile {
	width: 100%;
}

.body-normal {
	min-width: 1024px;
	overflow: auto;
}

.orange {
	background-color: #ff5c20 !important;
}

.pink {
	background-color: #d12c3f !important;
}

.orange-text {
	color: #ff5c20 !important;
}

.grey-both {
	color: #727272;
}

.checkbox-workaround {
	position: absolute;
	margin-left: 10px;
	margin-top: -1px;
}

.button:hover {
	cursor: pointer;
}

.btn-group > .btn-segmented:first-child:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group > .btn-segmented:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group > .btn-segmented:last-child:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-segmented {
	border: 1px solid #727272;
}

.vr {
	border: 0 solid #464646;
	border-left-width: 1px;
}

.link-url {
	text-decoration: underline;
}

.link-url:hover {
	text-decoration: underline !important;
}

.other-message .link-url {
	color: #000 !important;
}

.spinner {
	z-index: 10;
	position: absolute;
	left: 50%;
	top: 50%;
}

.spinner-inner {
	position: relative;
	left: -50%;
	top: -50%;
}

.grey-circle circle {
	stroke: #212121 !important;
}

.sidebar {
	position: relative;
	overflow-y: scroll;
	border-left: 1px solid var(--sidebar-border-color);
	overflow-x: hidden;
}

@media only screen and (max-width: 992px) {
	.login-img {
		display: none;
	}

	.back-position {
		position: absolute;
		right: 0;
		left: 50%;
		top: 0;
	}

	.vertical-center-inner {
		margin-top: 10px;
	}
}

.z-index-2 {
	z-index: 2000000000000;
}

button:focus {
	outline: none;
}

.no-padding-dialog {
	background: transparent !important;
	box-shadow: transparent;
}

.storage-upgrade-dialog .mat-dialog-container {
	background-color: #f3f1f1 !important;
	border-radius: 24px;
}

.top-0 {
	top: 0;
}

.bottom-0 {
	bottom: 0;
}

@include media-breakpoint-down(sm) {
	.text-center-on-tablet {
		text-align: center;
	}

	.authentication-center-middle {
		padding-right: 0;
	}
}

/**
	This class can be passed to the panelClass directive of of mat-select instances.
	This moves the selectable options below the input.
	This only works if the style is global
 */
.cn-selection-panel {
	margin-top: 35px;
	margin-left: 11px;
}

/**
Resetting Angular default which break mat-icon placements
 */
svg {
	vertical-align: initial;
}

#loopedin > .popover-count {
	background: var(--app-primary) !important;
	box-shadow: var(--app-primary) 0 0 0 0 !important;
	width: 18px !important;
	height: 18px !important;
	top: -2px !important;
	right: 2px !important;
}

.orange-tick {
	padding-left: 40px;
	min-height: 24px;
	background: url('/assets/images/promotional-checkmark.svg') no-repeat left top;

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
}

.dark {
	.icon-image {
		filter: brightness(0) invert(1);
	}
}

// TODO:: Create a component for empty state

.dark {
	.empty-state-text {
		color: #ffffff;
	}
}

.empty-state-text {
	opacity: 0.4;
	font-size: 14px;
	font-weight: $font-weight-normal;
	line-height: 20px;
}

/**
	used in dark theme to override default cdk-program-focused class background-color
	which gets added to the first mat-menu item when it's opened on mouseneter
*/
.dark .cdk-overlay-container {
	.breadcrumbs__menu-item.mat-menu-item.cdk-program-focused {
		background-color: transparent !important;

		&:hover {
			background-color: #333333 !important;
		}
	}
}

.global-search-dialog-section {
	em {
		font-style: normal;
	}

	.global-search-highlight-text em {
		background: #ffd0a5;
	}
}

.dark {
	.global-search-dialog-section {
		.global-search-highlight-text em {
			background: #4b5563;
		}
	}
}

.notification-item-section {
	em {
		font-style: normal;
		font-weight: 700;
	}
}

.selection-list-project-members {
	.mat-list-text {
		padding: 0 !important;
	}

	.mat-list-option {
		&[aria-selected='true'] {
			background-color: rgba(255, 144, 87, 0.1);
		}
	}

	.mat-pseudo-checkbox {
		transition: all 300ms ease;
		transition-property: width, opacity, margin-left;
		width: 0;
		opacity: 0;
		margin-left: 0;
		border: 0;
	}

	&.show-all-checkboxes,
	.mat-list-item:hover {
		.mat-pseudo-checkbox {
			border: 2px solid;
			width: 16px;
			opacity: 1;
			margin-left: 16px;

			&.mat-pseudo-checkbox-checked {
				border-color: transparent;
			}
		}
	}
}

.grey-icon {
	color: #9e9e9e;
}
