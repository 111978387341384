@use '@angular/material' as mat;

@mixin form-field-theme($theme) {
	@include mat.legacy-form-field-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$accent: map-get($config, accent);
	$grey: map-get($config, grey);

	.mat-form-field {
		.mat-form-field-ripple {
			background-color: mat.get-color-from-palette($grey, 400) !important;
		}

		.mat-hint {
			color: mat.get-color-from-palette($primary, '400-contrast');
		}

		&.bottom-padding-0 {
			.mat-form-field-wrapper {
				padding-bottom: 0;
			}
		}
	}

	.mat-form-field-appearance-fill {
		.mat-form-field-wrapper {
			padding: 0;

			.mat-form-field-flex {
				padding-top: 0;
				align-items: center;
				border-radius: 5px;
			}
		}

		.mat-form-field-underline {
			display: none;
		}
	}

	.form-field-outline,
	.mat-form-field-appearance-outline {
		.mat-form-field-wrapper {
			margin: 0;
		}

		.mat-form-field-flex {
			cursor: pointer;
			align-items: center;
			margin-top: -0.25em !important; // Dont change this value

			.mat-form-field-outline {
				color: mat.get-color-from-palette($primary, '400-contrast') !important;
				opacity: 0.6;

				> div {
					border-width: 1px;
				}
			}
		}

		.mat-form-field-subscript-wrapper {
			padding: 0 !important;
		}

		.mat-select {
			.mat-select-trigger {
				.mat-select-arrow-wrapper {
					opacity: 0;
				}
			}
		}

		.mat-form-field-label {
			color: mat.get-color-from-palette($primary, '400-contrast');
		}

		&.mat-focused {
			.mat-form-field-label {
				color: mat.get-color-from-palette($accent);
			}

			.mat-form-field-outline {
				> .mat-form-field-outline-start,
				.mat-form-field-outline-end {
					border-color: mat.get-color-from-palette($accent);
				}

				> .mat-form-field-outline-gap {
					border-bottom-color: mat.get-color-from-palette($accent);
				}
			}
		}

		&.with-chips {
			.mat-form-field-infix {
				padding: 0.4em 0;
			}
		}

		&.time-picker {
			.mat-form-field-flex {
				width: 105px;
			}
		}

		&.mat-form-field-appearance-outline {
			.mat-form-field-subscript-wrapper {
				padding: 0;
			}
		}
	}

	.mat-input-element {
		/*
    @include input-placeholder {
      color: mat.get-color-from-palette($primary, '300-contrast') !important;
    }
	*/
		caret-color: auto !important;
	}

	.mat-form-field-appearance-legacy {
		.mat-form-field-label {
			color: mat.get-color-from-palette($primary, '400-contrast');
		}

		&.mat-focused {
			.mat-form-field-label {
				color: mat.get-color-from-palette($primary, '500-contrast');
			}
		}
	}
}
