@use '@angular/material' as mat;
@import '../variables';

@mixin dialog-theme($theme) {
	@include mat.legacy-dialog-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$background: map-get($config, background);
	$background-dialog: mat.get-color-from-palette($background, 'dialog');

	.mat-dialog-container {
		padding: 40px !important;
		background: $background-dialog;
	}

	.mat-dialog-actions {
		margin: 40px 0 0 0;
		padding: 0 !important;
		min-height: auto !important;
		margin-bottom: 0 !important;
	}

	.mat-dialog-title {
		margin-bottom: 16px !important;
		line-height: 1.1;
		font-weight: $font-weight-bold;

		.mat-icon {
			height: 30px;
			width: 30px;
			font-size: 30px;
			display: inline;
		}
	}
}
