@use '@angular/material/index' as mat;
@import 'theme/styles/light-theme';
@import 'theme/styles/dark-theme';

$light-primary: map-get($light-theme, primary);
$dark-primary: map-get($dark-theme, primary);

body .bg-white {
	background: var(--background-color) !important;
}

.resize-none {
	resize: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-white {
	color: #ffffff;
}

.rounded-small {
	border-radius: 0.25rem !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.min-height-min-content {
	min-height: min-content;
}

.min-height-0 {
	min-height: 0;
}

.min-width-0 {
	min-width: 0;
}

.overflow-y-auto {
	overflow-y: auto !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

.absolute-center {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.z-index-1 {
	z-index: 1;
}

.dark {
	.text-muted {
		color: mat.get-color-from-palette($dark-primary, '400-contrast') !important;

		a {
			color: mat.get-color-from-palette($dark-primary, '400-contrast') !important;
			text-decoration: underline;
		}
	}

	.text-regular {
		color: mat.get-color-from-palette($dark-primary, '500-contrast') !important;

		a {
			color: mat.get-color-from-palette($dark-primary, '500-contrast') !important;
			text-decoration: underline;
		}
	}
}

.text-muted {
	color: mat.get-color-from-palette($light-primary, '400-contrast') !important;

	a {
		color: mat.get-color-from-palette($light-primary, '400-contrast') !important;
		text-decoration: underline;
	}
}

.text-regular {
	color: mat.get-color-from-palette($light-primary, '500-contrast') !important;

	a {
		color: mat.get-color-from-palette($light-primary, '500-contrast') !important;
		text-decoration: underline;
	}
}

.text-warn {
	color: mat.get-color-from-palette($mat-ruby-red, 500) !important;
}

.dark {
	.text-muted {
		color: mat.get-color-from-palette($dark-primary, '400-contrast') !important;
	}
}

.grey-border {
	border-color: mat.get-color-from-palette($mat-grey-light, 400) !important;
}

.nmt-2 {
	margin-top: -0.5rem !important;
}

.flex-1 {
	flex: 1 !important;
}

.opacity-0 {
	opacity: 0 !important;
}

// Scrollable container with dynamic height
.flex-scroll {
	height: 100%;
	position: relative;
	display: flex;

	&__container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		/* for Firefox */
		min-height: 0;

		&__scrollable-content {
			flex-grow: 1;
			overflow: auto;

			/* for Firefox */
			min-height: 0;
		}
	}
}

.skeleton-loading {
	display: inline-block;
	position: relative;
	overflow: hidden;
	background-color: #dddbdd;
	border-radius: 4px;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#fff, 0) 0,
			rgba(#fff, 0.2) 20%,
			rgba(#fff, 0.5) 60%,
			rgba(#fff, 0)
		);
		animation: shimmer 3s infinite;
		content: '';
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}
