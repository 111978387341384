$icon-size: 18px;
$light-background: #f2f2f2;
$light-icon-color: #000000de;
$light-background-active: #35180f;
$light-text-color-active: #ffffff;
$light-icon-color-active: #ffffff;

$dark-background: #85858559;
$dark-icon-color: #ffffff;
$dark-background-active: #e9e7e6;
$dark-text-color-active: #000000de;
$dark-icon-color-active: #000000de;

.mat-button-base {
	&.ch-secondary-btn {
		background: $light-background;

		&.active {
			background: $light-background-active !important;
			color: $light-text-color-active;

			.mat-icon.custom-icon g {
				fill: $light-icon-color-active;
			}
		}
	}

	&.mat-icon-button {
		.mat-button-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.mat-icon.custom-icon {
		height: $icon-size;
		width: $icon-size;
		display: inline-flex;

		g {
			fill: $light-icon-color;
		}
	}
}

.dark {
	.mat-button-base {
		&.ch-secondary-btn {
			background: $dark-background;

			&.active {
				background: $dark-background-active !important;
				color: $dark-text-color-active !important;

				.mat-icon.custom-icon g {
					fill: $dark-icon-color-active !important;
				}
			}

			.mat-icon.custom-icon g {
				fill: $dark-icon-color;
			}
		}
	}
}
