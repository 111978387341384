@mixin mat-icon-size($size: 24px) {
	font-size: $size !important;
	height: $size !important;
	width: $size !important;
	line-height: $size !important;

	::ng-deep svg {
		vertical-align: initial !important;
	}
}
