@use '@angular/material' as mat;
@import '../variables';

$button-toggle-height: 40px;

@mixin button-toggle-theme($theme) {
	@include mat.button-toggle-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$grey: map-get($config, grey);

	.mat-button-toggle-group {
		font-size: 0.857em;
		border-color: mat.get-color-from-palette($primary, '300-contrast');

		.mat-button-toggle {
			color: mat.get-color-from-palette($primary, '500-contrast');

			&.mat-button-toggle-disabled {
				color: mat.get-color-from-palette($grey, 500) !important;

				&.mat-button-toggle-checked {
					background: mat.get-color-from-palette($grey, 500) !important;
					color: mat.get-color-from-palette($primary, 500) !important;
				}
			}

			&.mat-button-toggle-checked {
				background: mat.get-color-from-palette($primary, '500-contrast');
				color: mat.get-color-from-palette($primary, 500);
				font-weight: $font-weight-bold;
			}

			.mat-button-toggle-label-content {
				line-height: $button-toggle-height;
			}
		}
	}
}
