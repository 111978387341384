@use '@angular/material' as mat;
@import 'light-theme';
@import 'dark-theme';

$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;

$border-radius: 0.5rem;

$success: #61bb00;

$link-color: #34180e;

$text-color-light: mat.get-color-from-palette($mat-light-theme-foreground-custom, 'text');
$background-color-light: map-get($mat-light-theme-background-custom, background);

$primary-dark: mat.get-color-from-palette($dark-primary, 'default');
$accent-dark: mat.get-color-from-palette($dark-accent, 'default');
$warn-dark: mat.get-color-from-palette($dark-warn, 'default');
$text-color-dark: mat.get-color-from-palette($mat-dark-theme-foreground-custom, 'text');
$background-color-dark: map-get($mat-dark-theme-background-custom, background);

$button-height: 40px;
$button-height-small: 24px;
$stroked-button-border-width: 1px;

$named-icon-height: 40px;
$named-icon-width: 40px;
$named-icon-border-radius: 40px;
