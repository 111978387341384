@use '@angular/material' as mat;
@import '../variables';

@mixin radio-theme($theme) {
	@include mat.legacy-radio-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$accent: map-get($config, accent);

	.mat-radio-button {
		.mat-radio-outer-circle {
			border-color: mat.get-color-from-palette($primary, '500-contrast');
		}

		.mat-radio-inner-circle {
			background-color: mat.get-color-from-palette($primary, '500-contrast');
		}

		&.mat-radio-checked {
			.mat-radio-outer-circle {
				border-color: mat.get-color-from-palette($accent) !important;
			}

			.mat-radio-inner-circle {
				background-color: mat.get-color-from-palette($accent) !important;
			}
		}
	}
}
