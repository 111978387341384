@import 'styles/variables';
@import 'styles/mixins/craftnote-mat-icon';

.craftnote-btn {
	&.mat-button,
	&.mat-raised-button,
	&.mat-stroked-button,
	&.mat-flat-button {
		font-weight: $font-weight-bold;
	}
}

.craftnote-dialog {
	&__title {
		&[mat-dialog-title] {
			font-weight: $font-weight-bold;
		}
	}
}

.craftnote-menu {
	.mat-menu-content {
		padding: 0 !important;
	}

	&.mat-menu-panel {
		min-height: unset;
		min-width: 200px !important;
		margin-top: 10px;
		box-shadow: -2px 0 20px 0 rgba(52, 24, 14, 0.25) !important;
		border-radius: 8px;
	}

	[mat-menu-item] {
		color: var(--app-primary-text-color);
		font-size: 14px;
		border-bottom: 1px solid #e5e5e5;
		text-align: center;
		font-weight: $font-weight-bold;
		height: 38px;
		line-height: 38px;

		&:last-child {
			border: none;
		}

		&:hover {
			color: var(--app-primary);

			mat-icon svg {
				.fill {
					fill: var(--app-primary);
				}

				.stroke {
					stroke: var(--app-primary);
				}
			}
		}

		mat-icon {
			margin-right: 5px;
			@include mat-icon-size(20px);
		}
	}

	&.with-icon {
		[mat-menu-item] {
			text-align: left;
		}
	}
}

.dark {
	.craftnote-menu mat-icon svg {
		.fill {
			fill: var(--app-primary-text-color);
		}

		.stroke {
			stroke: var(--app-primary-text-color);
		}
	}
}

// Page dimensions
.craftnote-full-page-container {
	max-width: 1200px;
}

mat-checkbox {
	.mat-checkbox-inner-container {
		margin-top: 1px;
	}

	.mat-checkbox-label {
		white-space: break-spaces;
	}
}

.file-view-dialog {
	mat-dialog-container {
		background-color: rgba(0, 0, 0, 0.7) !important;
	}
}
