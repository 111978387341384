@use './montserrat-font';
@use './material-icons-font';
@use '@angular/material' as mat;
@import 'styles/bootstrap';

/*
	Include the common styles for Angular Material. We include this here so that you only
	have to load a single css file for Angular Material in your app.
	Be sure that you only ever include this mixin once!
*/

/*
	Core styles
*/
@import 'styles/core';
@import 'styles/typography';
@import 'styles/variables';

/*
 *  Note: Add .mat-typography on body, or pass any other selector below
 */
@include mat.legacy-typography-hierarchy($typography);

@import 'styles/dark-theme';
@import 'styles/light-theme';
@import 'styles/custom-component-themes';
@import 'styles/pallete-classes';

@include custom-components-theme($light-theme);
html,
body {
	height: 100%;
}

body {
	margin: 0;
	background: $background-color-light;
	color: $text-color-light;
	@include mat.typography-level($typography, body-1);

	&.dark {
		@include custom-components-theme($dark-theme);
		background: $background-color-dark;
		color: $text-color-dark;
	}
}

.test-color {
	&-red {
		color: red;
	}
	color: blue;
}
