@use '@angular/material' as mat;
@import '../variables';

@mixin checkbox-theme($theme) {
	@include mat.legacy-checkbox-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$accent: map-get($config, accent);

	.mat-checkbox {
		label.mat-checkbox-layout {
			margin: 0;
		}

		.mat-checkbox-frame {
			border-color: mat.get-color-from-palette($primary, '500-contrast');
		}
	}
}
