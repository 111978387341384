@import 'variables';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';

// Removed ~bootstrap/scss/type

//
// Horizontal rules
//

hr {
	margin-top: $hr-margin-y;
	margin-bottom: $hr-margin-y;
	border: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//

small,
.small {
	@include font-size($small-font-size);
	font-weight: $font-weight-normal;
}

mark,
.mark {
	padding: $mark-padding;
	background-color: $mark-bg;
}

//
// Lists
//

.list-unstyled {
	@include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
}

.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: $list-inline-padding;
	}
}

// Blockquotes
.blockquote {
	margin-bottom: $spacer;
	@include font-size($blockquote-font-size);
}

.blockquote-footer {
	display: block;
	@include font-size($blockquote-small-font-size);
	color: $blockquote-small-color;

	&::before {
		content: '\2014\00A0'; // em dash, nbsp
	}
}

@import 'bootstrap/scss/images';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables'; // Has some uses
@import 'bootstrap/scss/buttons'; // Has some uses, wait for WEB-27
@import 'bootstrap/scss/badge'; // Has some uses
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/button-group'; // Has some uses
@import 'bootstrap/scss/input-group'; // Has some uses
@import 'bootstrap/scss/navbar'; // Has some uses
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/forms'; // Required for task time time picker. Remove once ngb-timepicker is removed from code;
