@use '@angular/material/index' as mat;
@import 'styles/variables';
@import 'theme/styles/light-theme';
@import 'theme/styles/dark-theme';

:host-context(.dark) {
	.project-status-progress-bar .mat-progress-bar-buffer {
		background-color: mat.get-color-from-palette($mat-grey-dark, 500) !important;
	}
}

.project-status-progress-bar-container {
	.project-status-progress-bar .mat-progress-bar-buffer {
		background-color: mat.get-color-from-palette($mat-grey-light, 400);
	}

	.project-status-progress-bar {
		height: 4px;
		border-radius: 4px;
	}
}
