@font-face {
	font-family: 'Montserrat';
	font-weight: 100;
	src: url('./font-files/Montserrat-Thin.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 100;
	font-style: italic;
	src: url('./font-files/Montserrat-ThinItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 200;
	src: url('./font-files/Montserrat-ExtraLight.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 200;
	font-style: italic;
	src: url('./font-files/Montserrat-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	src: url('./font-files/Montserrat-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	font-style: italic;
	src: url('./font-files/Montserrat-LightItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	src: url('./font-files/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: italic;
	src: url('./font-files/Montserrat-Italic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	src: url('./font-files/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	font-style: italic;
	src: url('./font-files/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	src: url('./font-files/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	font-style: italic;
	src: url('./font-files/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	src: url('./font-files/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	font-style: italic;
	src: url('./font-files/Montserrat-BoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 800;
	src: url('./font-files/Montserrat-ExtraBold.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 800;
	font-style: italic;
	src: url('./font-files/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 900;
	src: url('./font-files/Montserrat-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'Montserrat';
	font-weight: 900;
	font-style: italic;
	src: url('./font-files/Montserrat-BlackItalic.ttf') format('truetype');
}
