@use '@angular/material/index' as mat;
@import 'theme/styles/light-theme';
@import 'variables';

body {
	--app-primary: #{$primary};
	--app-success: #{$success};
}

body.dark {
	--color: white;
	--background-color: #000000;
	--chat-background-color: #1c1c1c;
	--chat-message-border-color: #5b5b5b;
	--reply-message-background-color: #343434;
	--background-color-offset: #424242;
	--untouched-opacity: 0.9;
	--wizard-nav-untouched-color: #d4b0a4;
	--sidebar-background-color: #1f1f1f;
	--sidebar-border-color: #2b2b2b;
	--sidebar-text-color: white;

	/*
	This background color is currently used for stripe input fields which use a dark text color
	that can not be changed to a bright text color.
	This background color allows for readable white as well as black text
	 */
	--background-color-for-dark-text-input: darkgrey;

	/**
	This background color is used for labels which are supposed to lightly contrast from the
	primary background color while still being able to display text in the mode's text color
	 */
	--background-color-for-label: darkgrey;
	--overview-background: #2c211d;
	--overview-font: #ffffff;
	--overview-line-background: #382e2a;
	--active-card-background: #776f6d;
	--app-background-color: #1a1a1a;

	--craftnote-table-header-background: #282828;
	--craftnote-table-cell-odd-background: #303030;
	--craftnote-table-cell-even-background: var(--craftnote-table-header-background);
	--craftnote-table-cell-even-background-v2: var(--craftnote-table-header-background);
	--craftnote-table-cell-hover-background: #453126;
	--craftnote-table-header-font: var(--overview-font);
	--craftnote-table-header-border-color: transparent;

	--project-status-disabled-color: #3b3b3b;
	--project-status-enabled-color: var(--app-primary);

	--time-tracking-sidebar-font-color: var(--overview-font);

	--filter-btn-background-color: #85858559;
	--filter-invert-btn-background-color: #eae7e6;
	--filter-font-color: white;
	--filter-invert-font-color: rgba(0, 0, 0, 0.87);
	--app-primary-text-color: white;
	--app-primary-inverted-text-color: #34180e;
	--app-border-color: #333333;

	--files-section-drop-area-background-color: #ff5c2033;
	--files-section-drop-area-border-color: var(--app-primary);
	--files-section-drop-area-color: var(--app-primary);
	--files-section-drop-area-disabled-color: #ffffff99;
	--files-section-drop-area-disabled-background-color: rgba(234, 231, 230, 0.25);
	--files-section-drop-area-disabled-border-color: #eae7e6;
	--files-section-tile-background-color: rgba(255, 255, 255, 0.1);
	--files-section-tile-preview-color: rgba(243, 243, 243, 0.1);
	--files-section-tile-border-color: #585858;

	--promotion-border-color: #2b2b2b;

	--panel-background-color: #333333;
}

body.light {
	--color: black;
	--background-color: white;
	--chat-background-color: white;
	--chat-message-border-color: #979797;
	--reply-message-background-color: #f6f6f6;
	--sidebar-background-color: white;
	--sidebar-text-color: #34180e;
	--sidebar-border-color: #e5e5e5;
	--background-color-offset: #ffffff;
	--untouched-opacity: 0.08;
	--wizard-nav-untouched-color: #34180e;
	--background-color-for-dark-text-input: --background-color;
	--background-color-for-label: #f9f8f7;
	--overview-background: #ffffff;
	--overview-font: #34180e;
	--overview-line-background: #f9f8f7;
	--active-card-background: #eae8e7;
	--app-background-color: #f9f9f9;

	--craftnote-table-header-background: var(--app-background-color);
	--craftnote-table-cell-odd-background: var(--active-card-background);
	--craftnote-table-cell-even-background: var(--craftnote-table-header-background);
	--craftnote-table-cell-even-background-v2: #f5f3f3;
	--craftnote-table-cell-hover-background: #00000013;
	--craftnote-table-header-font: var(--overview-font);
	--craftnote-table-header-border-color: #dee2e6;

	--project-status-disabled-color: #ded0d0;
	--project-status-enabled-color: var(--app-primary);

	--time-tracking-sidebar-font-color: var(--overview-font);

	--filter-btn-background-color: #f2f2f2;
	--filter-invert-btn-background-color: #34180e;
	--filter-font-color: rgba(0, 0, 0, 0.87);
	--filter-invert-font-color: white;
	--app-primary-text-color: #34180e;
	--app-primary-inverted-text-color: white;
	--app-border-color: #e5e5e5;

	--files-section-drop-area-background-color: rgba(255, 92, 32, 0.05);
	--files-section-drop-area-border-color: var(--app-primary);
	--files-section-drop-area-color: var(--app-primary);
	--files-section-drop-area-disabled-color: #34180e99;
	--files-section-drop-area-disabled-background-color: #eae7e640;
	--files-section-drop-area-disabled-border-color: #eae7e6;
	--files-section-tile-background-color: rgba(52, 24, 14, 0.03);
	--files-section-tile-preview-color: rgb(243, 243, 243);
	--files-section-tile-border-color: #d1cbc8;

	--promotion-border-color: #eae7e6;
	--panel-background-color: #eae7e6;
}

.color-primary {
	color: var(--app-primary) !important;
}

.color-success {
	color: var(--app-success) !important;
}

.color-warn {
	$config: mat.get-color-config($light-theme);
	$warn: map-get($config, warn);

	color: mat.get-color-from-palette($warn);
}
