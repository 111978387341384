.select-search-dropdown-panel {
	display: flex;
	flex-direction: column;
	max-height: 450px !important;

	.search-input,
	.multi-select-clear-button {
		flex: 0;
	}

	.select-options-container {
		flex: 1;
		overflow: auto;

		.mat-option {
			&.with-onclick-listener {
				cursor: pointer;
			}

			&.text-only {
				pointer-events: none;
				cursor: pointer;
				text-align: center;

				.mat-pseudo-checkbox {
					display: none;
				}
			}
		}
	}
}
