@use '@angular/material' as mat;
@import '../variables';

@mixin tabs-theme($theme) {
	@include mat.legacy-tabs-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$accent: map-get($config, accent);

	.mat-tab-label {
		font-weight: $font-weight-normal;
		color: mat.get-color-from-palette($primary, '300-contrast');
		opacity: 1 !important;
	}

	.mat-tab-label-active {
		color: mat.get-color-from-palette($accent);
		font-weight: $font-weight-bold;
	}

	.mat-tab-link-container .mat-ink-bar {
		background-color: mat.get-color-from-palette($accent) !important;
	}
}
