.mat-table {
	tbody {
		td.mat-cell {
			padding: 0 10px !important;

			&:first-of-type {
				padding-left: 24px !important;
			}

			&:last-of-type {
				padding-right: 24px !important;
			}
		}
	}
}
