@use '@angular/material' as mat;
@import '../variables';

@mixin table-theme($theme) {
	@include mat.legacy-table-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$foreground-text: map-get($theme, text);
	$background-color: mat.get-color-from-palette($background, 'background');
	$background-hover: mat.get-color-from-palette($background, 'hover');
	$grey: map-get($config, grey);

	.mat-table {
		background-color: $background-color !important;

		tbody {
			cursor: pointer;

			> .mat-row {
				&:hover {
					background: $background-hover !important;
				}

				&:nth-of-type(even) {
					background: mat.get-color-from-palette($grey, 200);
				}

				.mat-cell {
					border: 0;
					font-size: 12px;
				}
			}
		}

		.mat-header-cell,
		.mat-footer-cell {
			border: 0;
		}

		.mat-sort-header-content {
			color: $foreground-text;
			font-weight: $font-weight-bold;
			font-size: 14px;
			line-height: 18px;
		}

		.mat-sort-header-arrow {
			color: mat.get-color-from-palette($primary, '500-contrast') !important;
		}
	}
}
