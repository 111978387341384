@use '@angular/material' as mat;
@import '../variables';

@mixin icon-theme($theme) {
	@include mat.icon-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	$subtle-color: mat.get-color-from-palette($primary, '300-contrast');
	$grey: map-get($config, grey);

	.mat-icon.mat-icon-subtle {
		color: $subtle-color;
	}

	.mat-icon.mat-icon-grey {
		color: mat.get-color-from-palette($grey);
	}

	.mat-icon {
		g,
		path {
			mat-icon-subtle {
				fill: $subtle-color;
			}
			mat-icon-grey {
				fill: mat.get-color-from-palette($grey);
			}
		}
	}
}
