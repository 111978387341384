@use '@angular/material' as mat;
@import '../palettes';

@mixin core-theme($theme) {
	@include mat.legacy-core-theme($theme);
	$config: mat.get-color-config($theme);
	$accent: map-get($config, accent);

	.mat-primary {
		.mat-pseudo-checkbox-checked,
		.mat-pseudo-checkbox-indeterminate {
			background: mat.get-color-from-palette($accent) !important;
		}
	}
}
