@use '@angular/material' as mat;

@mixin selectTheme($theme) {
	@include mat.legacy-select-theme($theme);
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);

	.mat-option.mat-active {
		color: mat.get-color-from-palette($primary, '400-contrast') !important;
	}
	.mat-select-panel {
		max-width: 400px !important;
	}
}
