@use '@angular/material' as mat;
@import '../variables';

@mixin chips-theme($theme) {
	@include mat.legacy-chips-theme($theme);

	.mat-standard-chip {
		.mat-chip-remove {
			border: none;
			padding: 0;
			background: none;

			.mat-icon {
				width: 18px;
				height: 18px;
				font-size: 18px;
			}
		}
	}
}
