@use '@angular/material' as mat;
@import '../variables';

$icon_button_side_width: 24px;

@mixin button-theme($theme) {
	@include mat.legacy-button-theme($theme);
	$config: mat.get-color-config($theme);
	$accent: map-get($config, accent);

	.mat-button,
	.mat-icon-button,
	.mat-flat-button,
	.mat-raised-button {
		position: relative;
		line-height: $button-height !important;

		&.mat-button-small {
			line-height: $button-height-small !important;
			font-weight: $font-weight-normal;
		}

		&.loading {
			@include loading-state();

			&:not(.mat-accent):not(.mat-warn) {
				color: mat.get-color-from-palette($accent);
			}
		}
	}
	.mat-button {
		&.mat-button-disabled {
			color: mat.get-color-from-palette($light-grey, 500) !important;
		}
	}

	.mat-stroked-button {
		position: relative;
		line-height: calc($button-height - calc($stroked-button-border-width * 2)) !important;

		&.mat-button-small {
			line-height: calc($button-height-small - calc($stroked-button-border-width * 2)) !important;
			font-weight: $font-weight-normal;
		}

		&.loading {
			@include loading-state();

			&:not(.mat-accent):not(.mat-warn) {
				color: mat.get-color-from-palette($accent);
			}
		}
	}

	.mat-flat-button,
	.mat-raised-button {
		&.icon-button {
			font-weight: 600;

			.mat-button-wrapper {
				display: grid;
				grid-template-columns: $icon_button_side_width auto $icon_button_side_width;

				:nth-child(1) {
					margin: auto 0;
					grid-column: 1;
				}

				:nth-child(2) {
					text-align: center;
					grid-column: 2;
				}

				:nth-child(3) {
					margin: auto 0;
					grid-column: 3;
				}
			}
		}
	}
}

@mixin loading-state() {
	.mat-button-wrapper {
		visibility: hidden;
	}

	.button-loader {
		pointer-events: none;
		position: absolute;
		top: 3px;
		left: 3px;
		right: 3px;
		bottom: 3px;
		background: inherit;
		display: flex !important;
		align-items: center;
		justify-content: center;
	}
}
