@use '@angular/material' as mat;

@mixin datepicker-theme($theme) {
	@include mat.datepicker-theme($theme);
	$config: mat.get-color-config($theme);
	$accent: map-get($config, accent);

	.mat-datepicker-toggle {
		color: mat.get-color-from-palette($accent);
	}
}
