@use '@angular/material' as mat;
@import '../variables';
@import '../dark-theme';
@import '../light-theme';

@mixin autocomplete-theme($theme) {
	@include mat.legacy-autocomplete-theme($theme);
	$config: mat.get-color-config($theme);
	$grey: map-get($config, grey);

	.mat-option {
		font-weight: $font-weight-bold;

		&:not(:last-of-type) {
			border-bottom: 1px solid mat.get-color-from-palette($grey, 300);
		}
	}
}
