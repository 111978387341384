@use '@angular/material' as mat;
@import '../variables';

@mixin option-theme($theme) {
	$config: mat.get-color-config($theme);
	$primary: map-get($config, primary);
	.mat-option-text {
		color: mat.get-color-from-palette($primary, '500-contrast');
	}
	.mat-option-disabled {
		.mat-option-text {
			color: mat.get-color-from-palette($primary, '400-contrast');
		}
	}
}
