@use '@angular/material' as mat;
@import '../variables';

@mixin menu-theme($theme) {
	@include mat.legacy-menu-theme($theme);
	$config: mat.get-color-config($theme);
	$grey: map-get($config, grey);

	.mat-menu-panel {
		max-width: unset !important;
	}

	.mat-menu-content:not(:empty) {
		padding: 0 !important;
	}

	.mat-menu-content {
		.mat-menu-item:not(:last-of-type) {
			border-bottom: 1px solid mat.get-color-from-palette($grey, 300);
		}
	}
	.ch-disable-mat-menu + * .cdk-overlay-pane > div.mat-menu-panel {
		button {
			pointer-events: none;
		}

		> .mat-menu-content {
			padding-bottom: 0;
		}
	}
}
