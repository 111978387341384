@mixin common-selector-overlay-panel($class) {
	.#{$class} {
		&:not(.mat-tooltip-panel) {
			@content;
		}
	}
}

@include common-selector-overlay-panel('basic-dropdown-overlay-panel') {
	transform: translateX(0) translateY(25px) !important;
}

@include common-selector-overlay-panel('mat-select-overlay-panel') {
	transform: translateX(-10px) translateY(35px) !important;
}

@include common-selector-overlay-panel('mat-select-paginator-overlay-panel') {
	transform: translateX(-19px) translateY(27px) !important;
}
